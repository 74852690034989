<template>
  <div class="companies">
    <h1>Companies</h1>
    <div class="controls">
      <div class="search">
        <input type="text" placeholder="Search" v-model="search" />
      </div>
      <div class="meta">
        <span
          >Showing: {{ getCompaniesBySearch(search).length }} /
          {{ companyCount }}
        </span>
      </div>
    </div>

    <div class="admin-table companies-table">
      <div class="table-header">
        <span class="visma-id label">Visma ID</span>
        <span class="name label">Name</span>
        <span class="account-owner label">Account owner</span>
        <span v-if="appBrand === 'kvalitetsfisk'" class="group label"
          >Group</span
        >
      </div>
      <div
        v-for="company in getCompaniesBySearch(search)"
        :key="company.id"
        @click="navigateToCompany(company.vismaId)"
      >
        <span class="visma-id">{{ company.vismaId }}</span>
        <span class="name">{{ company.name }}</span>
        <span class="account-owner">
          {{ getUserNameByVismaId(company.accountOwnerVismaId) }}
        </span>
        <span v-if="appBrand === 'kvalitetsfisk'" class="group">
          {{ company.testGroup }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "Companies",
  data() {
    return {
      search: "",
    };
  },
  methods: {
    navigateToCompany: function (id) {
      this.$router.push({ name: "SingleCompany", params: { id } });
    },
  },
  computed: {
    ...mapState({
      companies: (state) => state.companies.companies,
      init: (state) => state.companies.init,
      appBrand: (state) => state.settings.appSettings.app,
    }),
    ...mapGetters("companies", [
      "getCompaniesBySearch",
      "companyCount",
      "getCompanyNameById",
    ]),
    ...mapGetters("users", ["getUserNameByVismaId"]),
  },
};
</script>
